import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import { styled } from '@mui/material/styles';
import { FormControlLabel, Switch } from '@mui/material';
import HeaderPage from '../_layout/elements/header';
import FooterPage from '../_layout/elements/footer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
export const Plan = () => {
  
   const IOSSwitch = styled((props) => (
      <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
   ))(({ theme }) => ({
      width: 42,
      height: 26,
      padding: 0,
      '& .MuiSwitch-switchBase': {
         padding: 0,
         margin: 2,
         transitionDuration: '300ms',
         '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
               backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
               opacity: 1,
               border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
               opacity: 0.5,
            },
         },
         '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
         },
         '&.Mui-disabled .MuiSwitch-thumb': {
            color:
               theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[600],
         },
         '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
         },
      },
      '& .MuiSwitch-thumb': {
         boxSizing: 'border-box',
         width: 22,
         height: 22,
      },
      '& .MuiSwitch-track': {
         borderRadius: 26 / 2,
         backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
         opacity: 1,
         transition: theme.transitions.create(['background-color'], {
            duration: 500,
         }),
      },
   }));

   useEffect(() => {
      document.title = 'Plans';
    }, []);

   return (
      <div>
         <HeaderPage />
         <section className="minibanner  plan  ">
            <div className="container" style={{ position: 'relative' }}>
               <div className="row justify-content-center">
                  <div className="col-md-8">
                     <div className="comon-inner-banner">
                        <div className="ldplan">
                           <div className="lddeg" style={{ transform: 'rotate(-0deg)' }}>
                              <div className="ldcol"></div>
                              <div className="ldimg"></div>
                           </div>
                        </div>
                        <div className="ldplan">
                           <div className="lddeg" style={{ transform: 'rotate( 160deg)' }}>
                              <div className="ldcol"></div>
                              <div className="ldimg"></div>
                           </div>
                        </div>
                        <div className="ldplan">
                           <div className="lddeg" style={{ transform: 'rotate(-160deg)' }}>
                              <div className="ldcol"></div>
                              <div className="ldimg"></div>
                           </div>
                        </div>
                        <div className="ldplan">
                           <div className="lddeg" style={{ transform: 'rotate(-0deg)' }}>
                              <div className="ldcol"></div>
                              <div className="ldimg"></div>
                           </div>
                        </div>
                        <div className="planmiddlecontent" >
                           <h2 className=" " data-aos="fade-up" data-aos-duration="900" data-aos-offset="200px"    >  <span className='text-blue'> Plan</span>   & Pricing </h2>
                           <p className=" " data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px"   > <span className='text-blue'>SelectRight.AI</span>  is one-stop solution to all your hiring needs
                              Helping you save time & money on finding right candidate and test platforms
                           </p>
                           <div className="plan-duration" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="200px"  >
                              <span className="duration active">Monthly</span>
                              <FormControlLabel
                                 control={<IOSSwitch sx={{ m: 1 }} />}
                              />
                              <span className="duration">Annually   <small >(Get 20% Extra) ​</small> </span>
                           </div>
                           <div class="try-it mt-4" data-aos="fade-up" data-aos-duration="1600" data-aos-offset="200px"  >
                              <h5>30 Day Free Trial</h5>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="      ">
            <div className="container" style={{ position: 'relative' }}>
               <div className="row justify-content-center">
                  <div className="col-md-10">
                     <div className="plan-tables-container">
                        <div className="plan-table-item" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px"  >
                           <div className="plan-title" >
                              <h3>Individual</h3>
                              <div className="pricing-description">
                                 For Entrepreneurs & Small Businesses
                              </div>
                           </div>
                           <div className="plan-cost-box">
                              <div className='plan-cost'>
                                 {/* <h5>Rs. 2900 </h5> */}
                                 <h5>Rs. <span className='blur fs26'>$$$$</span> </h5>
                                 <small>/pm</small>
                              </div>
                              <span>Discounted pricing valid only for limited period</span>
                           </div>
                           <div className="plan-btn">
                              <a href={process.env.REACT_APP_SSO_URL +'/sign-up'}  className='btn btn-blue w-100'> Start Trial</a>
                           </div>
                           <div className="item-features bggray">
                              <ul>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    10,000 candidates' analysis
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    1,000 Free Proctored Tests
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    500 Free Proctored Video Tests
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    1000 Bulk CV upload
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    1 user account
                                 </li>


                              </ul>
                           </div>
                           <div className="item-features  ">
                              <ul>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Unlimited job posts
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Automatic application tracking
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Talent pipeline
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Online assessments
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Automatic email management
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Live interview integration
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Interview scheduling
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Integration with Job boards
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Candidate Portals
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <div className="plan-table-item popularitem" data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200px"  >
                           <div className="ldplan">
                              <div className="lddeg" style={{ transform: 'rotate(180deg)' }}>
                                 <div className="ldcol"></div>
                                 <div className="ldimg"></div>
                              </div>
                           </div>
                           <div className="ldplan">
                              <div className="lddeg" style={{ transform: 'rotate(-0deg)' }}>
                                 <div className="ldcol"></div>
                                 <div className="ldimg"></div>
                              </div>
                           </div>
                           <div className="plan-title">
                              <h3>Business</h3>
                              <div className="pricing-description">
                                 For small to medium businesses
                              </div>
                           </div>
                           <div className="plan-cost-box">
                              <div className='plan-cost'>
                                 {/* <h5>Rs. 3900 </h5> */}
                                 <h5>Rs. <span className='blur fs26'>$$$$</span> </h5>
                                 <small>/pm</small>
                              </div>
                              <span>Discounted pricing valid only for limited period</span>
                           </div>
                           <div className="plan-btn">
                              <a href={process.env.REACT_APP_SSO_URL +'/sign-up'} className='btn btn-white w-100'> Start Trial</a>
                           </div>
                           <div className="item-features bgpopular">
                              <ul>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    20,000 candidates' analysis
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    2,000 Free Proctored Tests
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    1000 Free Proctored Video Tests
                                 </li>

                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    2000  Bulk CV upload
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    5 user account
                                 </li>
                              </ul>
                           </div>
                           <div className="item-features  ">
                              <ul>
                                 <li className='plantagline'>
                                    Includes everything in INDIVIDUAL +
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Custom Roles & recruiter profiles
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <div className="plan-table-item" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="200px"  >
                           <div className="plan-title">
                              <h3>Enterprise / Agency</h3>
                              <div className="pricing-description">
                                 For large enterprises and recruitment agencies
                              </div>
                           </div>
                           <div className="plan-cost-box">
                              <div className='plan-cost'>
                                 <h5>On Demand</h5>
                              </div>
                              <span>Discounted pricing valid only for limited period</span>
                           </div>
                           <div className="plan-btn">
                              <a href={process.env.REACT_APP_SSO_URL +'/sign-up'} className='btn btn-blue w-100'> Get in touch</a>
                           </div>
                           <div className="item-features bggray">
                              <ul>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Custom candidate analysis
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Custom Proctored Test
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Custom Test Proctored Video
                                 </li>

                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Custom Bulk CV upload
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Custom user accounts
                                 </li>
                              </ul>
                           </div>
                           <div className="item-features  ">
                              <ul>
                                 <li className='plantagline '>
                                    Includes everything in BUSINESS +
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Integration with legacy systems
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Customized Reports and Dashboards
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Customer Functions and Buttons
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    On-demand background verification
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Whatsapp / SMS / phone bridge
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    Dedicated Account Manager
                                 </li>
                                 <li>
                                    <span>
                                       <CheckCircleIcon />
                                    </span>
                                    24*7 Support
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <small>
                        All prices are in Excl of GST. For outside India, no additional taxes applicable*.<br />
                        Pricing is subject to change without any prior notice. Please refer to the detailed
                        <Link to="">
                           "terms and conditions"</Link> page here.
                     </small>
                  </div>
               </div>
            </div>
         </section>
         <section className="   section-pedding   ">
            <div className="container" style={{ position: 'relative' }}>
               <div className="row justify-content-center ">
                  <div className="col-md-10">
                     <div className="addons-box" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                        <div className="addons-title"     >
                           <h2 className>Add-ons </h2>
                        </div>




                        <div className="add-ons-flex">
                           <div className="flex1">
                              <div className="credittitle">
                                 <h3>Credits</h3>
                              </div>


                              <div className="addon-info">
                                 <span>1 Credit for CV analysis,  10 Credit for online test,  50 Credit for video assessment   </span>
                              </div>
                              <div className="add-ons-plans">
                                 <div className="add-ons-row">
                                    <label>
                                       500 Credits    </label>
                                    <div className="amount-value">
                                       <span className="currency-symbol">
                                          <CurrencyRupeeOutlinedIcon />
                                       </span>
                                       {/* 490 */}
                                       <span className='blur  '>  $$$ </span>
                                    </div>
                                 </div>
                                 <div className="add-ons-row">
                                    <label>   2500 Credits <small className='text-success   '> ( Save 20% )</small></label>
                                    <div className="amount-value">
                                       <span className="currency-symbol">
                                          <CurrencyRupeeOutlinedIcon />
                                       </span>
                                       {/* 1950 */}
                                       <span className='blur  '>   $$$$ </span>
                                    </div>
                                 </div>
                                 <div className="add-ons-row">
                                    <label>  10000 Credits  <small className='text-success   '> ( Save 50% )</small></label>
                                    <div className="amount-value">
                                       <span className="currency-symbol">
                                          <CurrencyRupeeOutlinedIcon />
                                       </span>
                                       {/* 4900 */}
                                       <span className='blur  '> $$$$</span> 
                                    </div>
                                 </div>

                              </div>

                           </div>
                           <div className="flex1">
                              <div className="credittitle">
                                 <h3>Other Features</h3>
                              </div>




                              <div className="add-ons-plans">

                                 <div className="add-ons-row">
                                    <label>Account Manager</label>
                                    <div className="amount-value">
                                       <span className="currency-symbol">
                                          <CurrencyRupeeOutlinedIcon />
                                       </span>
                                       {/* 990   */}                                 
                                       <span className='blur  '>  $$$$ </span>
                                       <small className='text-muted'>/pm</small>
                                    </div>
                                 </div>
                                 <div className="add-ons-row">
                                    <label>Additional SubUser</label>
                                    <div className="amount-value">
                                       <span className="currency-symbol">
                                          <CurrencyRupeeOutlinedIcon />
                                       </span>
                                       {/* 1990   */}
                                       <span className='blur  '>  $$$$ </span>
                                       <small className='text-muted'>/pm</small>
                                    </div>
                                 </div>
                                 <div className="add-ons-row">
                                    <label>IP Calling</label>
                                    <div className="amount-value">
                                       <span className="currency-symbol">
                                          <CurrencyRupeeOutlinedIcon />
                                       </span>
                                       {/* 290   */}
                                       <span className='blur  '>  $$$  </span>
                                       <small className='text-muted'>/pm</small>
                                    </div>
                                 </div>
                                 <div className="add-ons-row">
                                    <label>1 Page Website</label>
                                    <div className="amount-value">
                                       <span className="currency-symbol">
                                          <CurrencyRupeeOutlinedIcon />
                                       </span>
                                       {/* 1490   */}
                                       <span className='blur  '>  $$$$  </span>
                                       <small className='text-muted'>/pm</small>
                                    </div>
                                 </div>
                                 <div className="add-ons-row">
                                    <label> Domain name</label>
                                    <div className="amount-value-new">  Cost as per actuals from GoDaddy  </div>
                                 </div>
                              </div>

                           </div>


                        </div>



                     </div>
                  </div>
               </div>
               <div className="row justify-content-center d-none ">
                  <div className="col-md-12">
                     <div className="section-title" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px"    >
                        <h2 className>Discover SelectRight Features.</h2>
                     </div>
                  </div>
                  <div className="col-md-10">
                     <div className="Features-table" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px"  >
                        <div className="table-responsive">
                           <table className="table table-bordered ">
                              <thead>
                                 <tr>
                                    <th>Features</th>
                                    <th>Individual</th>
                                    <th>Business</th>
                                    <th>Enterprise / Agency </th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr>
                                    <td>
                                       <span className="feturename">Unlimited job posts</span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename">Automatic application tracking</span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename"> Talent pipeline</span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename"> Online assessments</span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename">Automatic email management</span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename">Live interview integration</span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename"> Interview scheduling</span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename">Integration with Job boards</span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename">Candidate Portals</span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename"> Custom Roles & recruiter profiles</span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename">Integration with legacy systems</span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename">Customized Reports and Dashboards</span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename">Customer Functions and Buttons</span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename">On-demand background verification</span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename">Whatsapp / SMS / phone bridge</span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename">Dedicated Account Manager</span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <span className="feturename">24*7 Support</span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-uncheck">
                                          <CancelIcon />
                                       </span>
                                    </td>
                                    <td>
                                       <span className=" f-check">
                                          <CheckCircleIcon />
                                       </span>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <FooterPage />
      </div>
   )
}