import React, { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Home } from './pages/home'
import { BookDemo } from './pages/book-demo'
import { Plan } from './pages/plan'
import { AboutUs } from './pages/about-us'
 

const RoutesList = () => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Home />} />
                 <Route path='about-us' element={<AboutUs/>} />
                <Route path='book-demo' element={<BookDemo />} />
                <Route path='plan' element={<Plan />} />
            </Routes>

        </Fragment>
    )
}

export default RoutesList