import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";  
import 'react-phone-number-input/style.css'
import { styled } from '@mui/material/styles';
import  {TextField, Select, MenuItem,Button,Modal,Box, Radio, RadioGroup ,FormGroup,Checkbox,FormControlLabel,Switch  } from '@mui/material';
import HeaderPage from '../_layout/elements/header';
import FooterPage from '../_layout/elements/footer';
import ourstory from "../assets/images/ourstory.jpeg";
import backgroundImage from "../assets/images/logo-watermark.png";
 
export const AboutUs = () => {
const [isYearly, setIsYearly] = useState(false);
const handleSwitchChange = (event) => {
setIsYearly(event.target.checked);
};
const IOSSwitch = styled((props) => (
<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
width: 42,
height: 26,
padding: 0,
'& .MuiSwitch-switchBase': {
padding: 0,
margin: 2,
transitionDuration: '300ms',
'&.Mui-checked': {
transform: 'translateX(16px)',
color: '#fff',
'& + .MuiSwitch-track': {
backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
opacity: 1,
border: 0,
},
'&.Mui-disabled + .MuiSwitch-track': {
opacity: 0.5,
},
},
'&.Mui-focusVisible .MuiSwitch-thumb': {
color: '#33cf4d',
border: '6px solid #fff',
},
'&.Mui-disabled .MuiSwitch-thumb': {
color:
theme.palette.mode === 'light'
? theme.palette.grey[100]
: theme.palette.grey[600],
},
'&.Mui-disabled + .MuiSwitch-track': {
opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
},
},
'& .MuiSwitch-thumb': {
boxSizing: 'border-box',
width: 22,
height: 22,
},
'& .MuiSwitch-track': {
borderRadius: 26 / 2,
backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
opacity: 1,
transition: theme.transitions.create(['background-color'], {
duration: 500,
}),
},
}));

useEffect(() => {
   document.title = 'About us';
 }, []);

return (
<div>
   <HeaderPage />
   <section className="minibanner  aboutus  ">
      <div className="container" style={{position:'relative'}}>
         <div className="row justify-content-center">
            <div className="col-md-8">
               <div className="comon-inner-banner">
                  <div className="ldplan">
                     <div className="lddeg" style={{transform:'rotate(-0deg)'}}>
                        <div className="ldcol"></div>
                        <div className="ldimg"></div>
                     </div>
                  </div>
                  <div className="ldplan">
                     <div className="lddeg" style={{transform:'rotate( 160deg)'}}>
                     <div className="ldcol"></div>
                     <div className="ldimg"></div>
                  </div>
               </div>
               <div className="ldplan">
                  <div className="lddeg" style={{transform:'rotate(-160deg)'}}>
                     <div className="ldcol"></div>
                     <div className="ldimg"></div>
                  </div>
               </div>
               <div className="ldplan">
                  <div className="lddeg" style={{transform:'rotate(-0deg)'}}>
                     <div className="ldcol"></div>
                     <div className="ldimg"></div>
                  </div>
               </div>
               <div className="planmiddlecontent" >
                  <h2 className=" " data-aos="fade-up" data-aos-duration="900" data-aos-offset="200px"    > Empowering the Future of Recruitment </h2>
                <p data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200px">Unleash the Power of AI with Our Cutting-Edge <span className="text-blue"> Recruitment </span> Software </p>
             
               </div>
            </div>
         </div>
      </div>
</div>
</section>
<section className="section-pedding  our-story " >
   <div className="container"  >
      <div className="row align-items-center justify-content-center ">
<div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 ">
<div className="weare"  >
 

<p data-aos="fade-up" data-aos-duration="900" data-aos-offset="200px"><a href='https://ngnext.tech/' target='_blank'>NG-NeXT Tech</a> proudly introduces SelectRight.ai, an innovative solution 
forged from experience tackling the complexities of hiring. We address 
the challenge of sifting through applications and preventing fraud, 
while our seamless integration with SkillWise empowers powerful skill 
assessment through advanced proctoring and AI analysis. SelectRight.ai 
reflects our dedication to efficient, honest, and precise hiring 
practices.</p>

</div>
</div> 
      </div>
   </div>
</section>
<section className="section-pedding  aboutng   "  >
   <div className="container"  >
      <div className="row align-items-center justify-content-center ">
<div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 ">
<div className="padding-right text-center  ">
<div className="section-title mb-4  "  data-aos="fade-up" data-aos-duration="800" data-aos-offset="200px"    >
               <h2 className>About NG-NeXT Tech</h2>
         
            </div>

<p data-aos="fade-up" data-aos-duration="900" data-aos-offset="200px"><a href='https://ngnext.tech/' target='_blank'> NG-NeXT Tech</a> , was conceived in 2020 with the aim of building the world’s most safe, secure, and virtually indestructible Digital Assets Exchange system. Inclusive growth fuelled by humanistic values in conflation with AI-based automation has been the driving force behind the company's fast growth. Our forte lies in curating scalable, Ai-powered, disruptive tech products that aim to solve real-time problems. Be it AI-based hiring automation solutions or proctored-testing builders, there is a core solution for all your bespoke business needs.</p>

<p>To know more, please visit <Link to='https://ngnext.tech/' target='_blank'>NG-NeXT Tech</Link>  </p>
</div>
</div>  
      </div>
   </div>
</section>
 
<FooterPage />
</div>
)
}
