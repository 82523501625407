import React from 'react'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
function FooterPage() {
return (
<div>
 
<footer>
   <div className="footer-elment" />
      <div className="copyright-section">
         <div className="container">
            <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12  ">
               <div className="contact-information">
               <div className="contact-row">
                  
                           <div className="contact-information">
                           
                              <h5> Contact Us : </h5>
                           </div>
                        </div>
                        <div className="contact-row">
                           <div className="contact-icon">
                              <MailOutlineIcon/>
                           </div>
                           <div className="contact-information">
                             
                              <h6><a href="mailtoto:ajayjangid82341@gmail.com">sales@ngnext.tech</a></h6>
                           </div>
                        </div>
                     </div>

                     </div>
               <div className="col-lg-8 col-md-12  ">
                  <div className="copyright ">
                     <p>
                        <i className="bx bx-copyright" /> Copyright  <strong> NG-NeXT PRIVATE LIMITED.</strong> All Rights Reserved
                        Designed by <a target="_blank" href="https://ngnext.tech/"> NG-NeXT PRIVATE LIMITED</a>
                     </p>
                  </div>
                  <div className="footer-bottom ">
                     {/* 
                     <div class="footer-links">
                        <a href="#">Terms of Use</a>
                        <a href="#">Privacy Policy</a>
                        <a href="#">Sitemap</a>
                     </div>
                     */}

<div class="social-media">
                     {/* <a href="https://www.instagram.com/ng.next/" target="_blank"><i class="lab la-instagram"></i></a>
                     <a href="https://twitter.com/NGNeXTTech" target="_blank"><i class="lab la-twitter"></i></a> */}
                     <a href="https://www.linkedin.com/showcase/selectright-ai/" target="_blank"><i class="lab la-linkedin-in"></i></a>
                  </div>
                  </div>
               </div>
        
            </div>
         </div>
      </div>
</footer>
</div>
)
}
export default FooterPage