import React, {  } from 'react';
import { CircularProgress } from '@mui/material'; 
 
export default function ContentLoader
() { 
  return ( 
   <> 
    {/* <div className="page-loader" style={{display:'flex'}}  > */}
                  <div className="inner-loader">
                     <CircularProgress />
                     <span>Loading... Won't be a minute</span>
                  </div>
               {/* </div> */}
   </>
  )
}