



const MainPage = (props) => {
    return (
        <>

            <div class="layout-wrapper">
             
                {/* <SidebarPage/> */}
                {props.children}
             

            </div>

        </>
    )
}
export default MainPage;
