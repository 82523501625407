import React from 'react';
import ReactDOM from 'react-dom/client';
 
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';


const injectGA = () => {
  if (typeof window == 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'UA-260288841-1');
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=UA-260288841-1"
    />
    <script>{injectGA()}</script>
 
  <BrowserRouter>
    <App />
  </BrowserRouter>
</React.StrictMode>
);

 
reportWebVitals();
